exports.components = {
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-updates-tsx": () => import("./../../../src/pages/updates.tsx" /* webpackChunkName: "component---src-pages-updates-tsx" */),
  "component---src-templates-club-js": () => import("./../../../src/templates/Club.js" /* webpackChunkName: "component---src-templates-club-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/Location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-poule-js": () => import("./../../../src/templates/Poule.js" /* webpackChunkName: "component---src-templates-poule-js" */),
  "component---src-templates-sheets-games-js": () => import("./../../../src/templates/sheets/Games.js" /* webpackChunkName: "component---src-templates-sheets-games-js" */),
  "component---src-templates-sheets-scores-js": () => import("./../../../src/templates/sheets/Scores.js" /* webpackChunkName: "component---src-templates-sheets-scores-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/Team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

